<template>
  <vx-card style="padding-left: 10px" :key="rerenderKey">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      </vs-col>

      <vs-col vs-justify="right" vs-align="right" vs-w="6">
        <vs-button style="float: right" align="right" icon-pack="feather" icon="icon-edit" @click="createProductHandler"
          >Create Product</vs-button
        >
      </vs-col>
    </vs-row>

    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="products"
        @change-page="handleChangePage"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
        search
        @search="handleSearch"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between items-center">
          <vs-select placeholder="10" autocomplete v-model="dataTableParams.limit">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in limitOptions" />
          </vs-select>
        </div>

        <template slot="thead">
          <vs-th>Image</vs-th>
          <vs-th sort-key="name">Product Name</vs-th>
          <vs-th sort-key="supplier">Supplier Name</vs-th>
          <vs-th sort-key="supplier">Fresh Price</vs-th>
          <vs-th sort-key="supplier">Stock Status</vs-th>
          <!-- <vs-th sort-key="stock">Stock</vs-th> -->
          <vs-th>Hide Status</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="getImage(data[indextr])">
              <div style="width: 100px; height: auto">
                <img class="img-fluid" v-if="isImageExists(data[indextr])" :src="getImage(data[indextr])" alt="" />
              </div>
            </vs-td>
            <vs-td :data="data[indextr].name">{{ data[indextr].name || 'N/A' }}</vs-td>
            <vs-td :data="data[indextr].supplier">{{ data[indextr].supplier || 'N/A' }}</vs-td>
            <vs-td :data="data[indextr].supplier">{{ data[indextr].price | formatDecimals }}</vs-td>
            <vs-td :data="data[indextr].supplier">{{ data[indextr].stock || 'N/A' }}</vs-td>
            <vs-td :data="data[indextr].hideStatus">
              <p v-if="data[indextr].hideStatus === true">Hidden</p>
              <p v-else>Not Hidden</p>
            </vs-td>
            <!-- <vs-td :data="data[indextr].stock">{{
              data[indextr].stock || "N/A"
            }}</vs-td> -->
            <vs-td :data="data[indextr]._id">
              <vx-tooltip
                class="mb-2"
                style="display: flex; align-items: center; justify-content: center"
                text="Share Product"
              >
                  <vs-button @click="shareProduct(data[indextr]._id)" type="border" size="small" icon-pack="feather" icon="icon-share" class="mr-2" />
              </vx-tooltip>
              <vx-tooltip
                class="mb-2"
                style="display: flex; align-items: center; justify-content: center"
                text="View/Edit Product"
              >
                <router-link
                  exact
                  :class="[{ 'router-link-active': activeLink }, 'nav-link flex items-center']"
                  :to="getEditLink(data[indextr]._id)"
                  target="target"
                >
                  <vs-button type="border" size="small" icon-pack="feather" icon="icon-edit" class="mr-2" />
                </router-link>
              </vx-tooltip>

              <!--              <vx-tooltip
                              style="display: flex; align-items: center; justify-content: center;"
                              text="Delete Product"
                            >
                              <vs-button
                                type="border"
                                size="small"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click="deleteHandler(data[indextr]._id)"
                                class="mr-2"
                              ></vs-button>
                            </vx-tooltip>-->
              <div v-if="data[indextr].hideStatus === false">
                <vx-tooltip
                  class="mb-2"
                  style="display: flex; align-items: center; justify-content: center"
                  text="Hide Product"
                >
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-eye"
                    color="danger"
                    @click="toggleStatus(data[indextr])"
                    class="mr-2"
                  ></vs-button>
                </vx-tooltip>
              </div>
              <div v-if="data[indextr].hideStatus === true">
                <vx-tooltip
                  class="mb-2"
                  style="display: flex; align-items: center; justify-content: center"
                  text="Unhide Product"
                >
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click="toggleStatus(data[indextr])"
                    class="mr-2"
                  ></vs-button>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <div class="m-2">
        <span class="mr-2">
          {{ dataTableParams.page * dataTableParams.limit - (dataTableParams.limit - 1) }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            v-model="dataTableParams.page"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from 'vuex';
import _ from "lodash";

export default {
  components: {},
  data() {
    return {
      noDataText: 'Loading...',
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      rerenderKey: 0,
      dataTableParams: {
        search: '',
        sort: '_id',
        dir: 'desc',
        page: 1,
        limit: 25,
      },
      serverResponded: false,
      awaitingSearch: null,
      productIdToDelete: null,
      products: [],
      loggedInUserType: '',
      limitOptions: [5, 10, 25, 50, 100],
      hideStatus: false,
    };
  },
  methods: {
    ...mapActions('storeProducts', ['fetchProducts', 'removeProduct', 'updateProductHideStatus']),
    ...mapActions("ecommerce",["algoliaSync"]),
    shareProduct(productId){
      const domain = window.location.origin;
      const url = domain + "/p/" + productId
      navigator.clipboard.writeText(url).then(() => {
        this.$vs.notify({
          title: 'Success',
          text: "Product URL copied successfully!",
          color: 'success',
        });
      }).catch(err => {
        console.log("Failed to copy text: ", err);
      });
    },
    isImageExists(data) {
      if (data.images && data.images.image) {
        return true;
      }
      return false;
    },
    getImage(data) {
      if (data.images && data.images.image) {
        return data.images.image;
      }
      return null;
    },
    getProducts() {
      this.$vs.loading();
      let self = this;
      this.fetchProducts(self.dataTableParams)
        .then((result) => {
          this.$vs.loading.close();
          self.serverResponded = true;
          self.products = result.data.data.docs;
          self.totalDocs = result.data.data.pagination.total;
          self.page = result.data.data.pagination.page;
          self.currentPage = result.data.data.pagination.page;
          if (result.data.data.docs.length === 0) this.noDataText = 'No products found';
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err)
        });
    },
    handleSort(key, active) {
      if (key) {
        this.serverResponded = false;
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.$router.replace({ query: { ...this.dataTableParams } });
        this.getProducts();
      }
    },
    handleSearch: _.debounce(function(searching) {
      this.dataTableParams.search = searching;
      if (searching !== this.$route.query.search) {
        this.dataTableParams.page = 1;
      }
      this.$refs.table.currentx = 1;
      this.$router.replace({ query: { ...this.dataTableParams } });
      this.serverResponded = false;
      this.getProducts();
    }, 500),
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.$router.replace({ query: { ...this.dataTableParams } });
      this.getProducts();
    },
    forceRerender() {
      this.rerenderKey += 1;
    },
    createProductHandler() {
      let createRoute = '/super-admin/store/products/create';

      if (this.loggedInUserType === 'admin') createRoute = '/admin/store/products/create';

      this.$router.push(createRoute);
    },
    viewEditHandler(id) {
      let editRoute = `/super-admin/store/products/${id}/edit`;

      if (this.loggedInUserType === 'admin') editRoute = `/admin/store/products/${id}/edit`;

      this.$router.push(editRoute);
    },
    getEditLink(id) {
      let editRoute = `/super-admin/store/products/${id}/edit`;
      if (this.loggedInUserType === 'admin') editRoute = `/admin/store/products/${id}/edit`;
      return editRoute;
    },
    deleteHandler(productId) {
      this.productIdToDelete = productId;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text: 'Are you sure to remove this product ?',
        accept: this.deleteProduct,
      });
    },
    deleteProduct() {
      this.$vs.loading();
      this.removeProduct(this.productIdToDelete)
        .then((res) => {
          this.getProducts();
          this.$vs.loading.close();
          this.$vs.notify({
            title: 'Success',
            text: 'Product deleted successfully',
            color: 'success',
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: 'Error',
            text: err.data.message,
            color: 'danger',
          });
        });
    },
    toggleStatus(data) {
      data.hideStatus = data.hideStatus === true ? false : true;
      this.$vs.loading();
      this.updateProductHideStatus({ id: data._id, data: data })
        .then((res) => {
          this.getProducts();
          this.$vs.loading.close();
          this.algoliaSync()
          this.$vs.notify({
            title: 'Success',
            text: "Product's hide status changed successfully",
            color: 'success',
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: 'Error',
            text: err.data.message,
            color: 'danger',
          });
        });
    },
    fillSearchInput() {
      this.$nextTick(() => {
        const searchInput = this.$refs.table.$el.querySelector('.vs-table--search input[type="text"]');
        if (this.$route.query.search && searchInput) {
          searchInput.value = this.$route.query.search;
          searchInput.dispatchEvent(new Event('input', { bubbles: true }));
        }
      })
    }
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit > parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    'dataTableParams.page': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.$router.replace({ query: { ...this.dataTableParams } });
        this.getProducts();
      }
    },
    'dataTableParams.limit': function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        if (newlimit !== parseInt(this.$route.query.limit)) {
          this.dataTableParams.page = 1;
        }
        this.dataTableParams.limit = newlimit;
        this.$router.replace({ query: { ...this.dataTableParams } });
        this.getProducts();
      }
    },
    // 'dataTableParams.search': function (newSearch, oldSearch) {
    //   if (newSearch !== oldSearch) {
    //     this.handleSearch(newSearch);
    //   }
    // },
    // rerenderKey: function(newVal, oldVal) {
    //   if (oldVal !== newVal) {
    //     this.getAvailableRoles();
    //   }
    // }
  },
  created() {
    this.dataTableParams.search = this.$route.query.search || '';
    this.dataTableParams.sort = this.$route.query.sort || '_id';
    this.dataTableParams.dir = this.$route.query.dir || 'desc';
    this.dataTableParams.page = parseInt(this.$route.query.page) || 1;
    this.dataTableParams.limit = parseInt(this.$route.query.limit) || 25;

    this.getProducts();
    this.loggedInUserType = this.$store.state.AppActiveUser.userType;
  },
  mounted() {
    this.fillSearchInput()
  }
};
</script>
